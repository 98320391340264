import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ThemeToggler from "components/common/ThemeToggler";
import { useState } from "react";
import { Dropdown, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfileDropdownMenu from "./ProfileDropdownMenu";
import { useAuth } from "providers/AuthProvider";

const NavItemsSlim = () => {
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const { user } = useAuth();
  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      <Nav.Item>
        <ThemeToggler slim />
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0"
            variant=""
          >
            {user?.name}
            <FontAwesomeIcon icon={faChevronDown} className="fs-10" />
          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
      </Nav.Item>
    </div>
  );
};

export default NavItemsSlim;
