import axios from "axios";
import ForgotPasswordForm from "components/modules/auth/ForgotPasswordForm";
import AuthSimpleLayout from "layouts/AuthSimpleLayout";
import { useState } from "react";
import { useMutation } from "react-query";
import { Navigate } from "react-router-dom";

const postForgotPassword = async (data: any) => {
  return await axios.post("/api/user/forgot-password", data);
};

const ForgotPassword = () => {
  const { isLoading, data, mutate, error } = useMutation(postForgotPassword);
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = e.currentTarget.email.value;
    setEmail(email);
    await mutate({ email });
  };

  if (data?.data && !error) {
    return (
      <Navigate
        to="/pages/authentication/simple/forgot-confirm"
        replace
        state={{ email, recovery: true }}
      />
    );
  }

  return (
    <AuthSimpleLayout className="col-xxl-4">
      <ForgotPasswordForm onSubmit={handleSubmit} loading={isLoading} />
    </AuthSimpleLayout>
  );
};

export default ForgotPassword;
