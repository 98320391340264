import Button from "components/base/Button";
import { Form, InputGroup } from "react-bootstrap";

const ResetPasswordForm = ({
  onSubmit,
  validationError,
  loading,
}: {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  validationError: string | null;
  loading: boolean;
}) => {
  return (
    <div className="text-center mb-6">
      <h4 className="text-body-highlight">Crie uma nova senha</h4>
      <p className="text-body-tertiary">Digite sua nova senha abaixo</p>
      <Form className="mt-5" onSubmit={onSubmit}>
        <Form.Control
          className="mb-2"
          id="password"
          type="password"
          placeholder="Digite uma nova senha"
          required
          isInvalid={!!validationError}
        />
        <InputGroup hasValidation>
          <Form.Control
            id="confirmPassword"
            type="password"
            placeholder="Confirme a nova senha"
            required
            isInvalid={!!validationError}
          />
          <Form.Control.Feedback type="invalid">
            {validationError}
          </Form.Control.Feedback>
        </InputGroup>
        <Button
          variant="primary"
          className="mt-4 w-100"
          type="submit"
          loading={loading}
          disabled={loading}
          loadingPosition="end"
        >
          Criar nova senha
        </Button>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
