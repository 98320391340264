import Avatar from "components/base/Avatar";
import ThemeToggler from "components/common/ThemeToggler";
import { useAppContext } from "providers/AppProvider";
import { useAuth } from "providers/AuthProvider";
import { Dropdown, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfileDropdownMenu from "./ProfileDropdownMenu";

const NavItems = () => {
  const {
    config: { navbarPosition },
  } = useAppContext();
  const { user } = useAuth();

  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      <Nav.Item>
        <ThemeToggler className="px-2" />
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            <Avatar variant="name" size="l">
              {user?.name?.charAt(0)}
            </Avatar>
          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
      </Nav.Item>
    </div>
  );
};

export default NavItems;
