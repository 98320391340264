import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { Cliente } from "data/cliente";
import moment from "moment";
import { Link } from "react-router-dom";

export const clienteTablecolumns: ColumnDef<Cliente>[] = [
  {
    accessorKey: "razaoSocial",
    header: "Razão Social",
    cell: ({ row: { original } }) => {
      const { razaoSocial, cnpj } = original;
      return (
        <Link
          to={`/cliente/${cnpj}`}
          className=" d-flex align-items-center text-body"
        >
          <p className="mb-0 text-body-emphasis fw-semibold">{razaoSocial}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: "40%", minWidth: "200px" },
        className: "px-1",
      },
      cellProps: { className: "align-middle white-space-nowrap py-2" },
    },
  },
  {
    accessorKey: "cnpj",
    header: "CNPJ",
    meta: {
      headerProps: { style: { width: "20%" } },
      cellProps: { className: "text-body" },
    },
  },
  {
    accessorKey: "lastRenew",
    header: "Última  renovação",
    cell: ({ row: { original } }) => {
      const { lastRenew } = original;
      return moment(lastRenew).format("DD/MM/YY HH:mm");
    },
    meta: {
      headerProps: {
        style: { width: "21%", minWidth: "200px" },
        className: "text-end",
      },
      cellProps: {
        className: "text-end text-body-tertiary",
      },
    },
  },
  {
    accessorKey: "nextRenew",
    header: "Próxima renovação",
    cell: ({ row: { original } }) => {
      const { nextRenew } = original;
      return moment(nextRenew).format("DD/MM/YY HH:mm");
    },
    meta: {
      headerProps: {
        style: { width: "19%", minWidth: "200px" },
        className: "text-end pe-0",
      },
      cellProps: {
        className: "text-body-tertiary text-end",
      },
    },
  },
];

const ClienteTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default ClienteTable;
