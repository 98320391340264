import AppProvider from "providers/AppProvider";
import BreakpointsProvider from "providers/BreakpointsProvider";
import SettingsPanelProvider from "providers/SettingsPanelProvider";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  QueryClientProvider as TanstackProvider,
  QueryClient as TanstackClient,
} from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import { router } from "Routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={new QueryClient()}>
      <TanstackProvider client={new TanstackClient()}>
        <AppProvider>
          <SettingsPanelProvider>
            <BreakpointsProvider>
              <RouterProvider router={router} />
            </BreakpointsProvider>
          </SettingsPanelProvider>
        </AppProvider>
      </TanstackProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
