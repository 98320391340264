import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export type ProdutoResumoType = {
  total: number;
  ativos: number;
  promocao: number;
  desconto: number;
};

export type EmpresaType = {
  id: number;
  codigo: number;
  nomeFantasia: string;
};

export type UnidadeMedidaType = {
  id: number;
  sigla: string;
  descricao: string;
};

export type DepartamentoType = {
  id: number;
  codigo: number;
  descricao: string;
};

export type CategoriaType = {
  id: number;
  codigo: number;
  descricao: string;
};

export type PrincipioAtivoType = {
  id: number;
  codigo: number;
  descricao: string;
};

export type FabricanteType = {
  id: number;
  codigo: number;
  descricao: string;
};

export type ProdutoTableType = {
  id: number | undefined;
  codigo: number | undefined;
  descricao: string;
  codigoBarras: string;
  fabricante: string;
  principioAtivo: string;
  departamento: string;
  estoque: number;
  categorias: string[];
};

export type ProdutoEmpresaType = {
  idEmpresa: number;
  idRegraFiscal: number | undefined;
  valor: number;
  precoCompra: number;
  margem: number;
  descontoMaximo: number | undefined;
  desconto: number | undefined;
  estoqueMinimo: number;
  precoAberto: boolean;
};

export type CriarProdutoEmpresaDtoType = {
  idEmpresa: number;
  idRegraFiscal: number | undefined;
  valor: number;
  precoCompra: number;
  margem: number;
  descontoMaximo: number | undefined;
  desconto: number | undefined;
  estoqueMinimo: number;
  precoAberto: boolean;
};

export type CriarProdutoDtoType = {
  idFabricante: number | undefined;
  idDepartamento: number | undefined;
  descricao: string;
  fracionado: number;
  idUnidadeMedida: number;
  controleEspecial: boolean;
  rms: string;
  pmc: number;
  idPrincipioAtivo: number | undefined;
  codigosDeBarras: string[];
  inativo: boolean;
  // lista: string;
  // tipoControle: string;
  // receituario: string;
  empresas: CriarProdutoEmpresaDtoType[];
  idsCategorias: number[];
};

export const produtosBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: "Retaguarda",
    url: "#!",
  },
  {
    label: "Cadastros",
    url: "#!",
  },
  {
    label: "Produtos",
    active: true,
  },
];

export const produtoBreadcrumbItems = (
  codigo?: string
): PageBreadcrumbItem[] => [
  {
    label: "Retaguarda",
    url: "#!",
  },
  {
    label: "Cadastros",
    url: "#!",
  },
  {
    label: "Produto",
    url: "#!",
  },
  {
    label: codigo ?? "Novo",
    active: true,
  },
];
