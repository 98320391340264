import axios from "axios";
import Button from "components/base/Button";
import Section from "components/base/Section";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import AssinaturaTable from "components/tables/AssinaturaTable";
import { assinaturaBreadcrumbItems, CartItemType } from "data/assinatura";
import { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";

const fetchProducts = async () => {
  const { data } = await axios.get(
    process.env.REACT_APP_API_CUSTOMER_URL + "/product"
  );
  return data;
};

const fetchContract = async ({ queryKey }: any) => {
  const [, id] = queryKey;
  const { data } = await axios.get(
    process.env.REACT_APP_API_CUSTOMER_URL + "/contract?customerCnpj=" + id
  );
  return data;
};

const putContract = async ({ cnpj, data }: { cnpj: string; data: any }) => {
  const response = await axios.put(
    process.env.REACT_APP_API_CUSTOMER_URL + "/contract/" + cnpj,
    data
  );
  return response.data;
};

export function Assinatura() {
  const { id } = useParams();
  const { data: products, isLoading: loadingProducts } = useQuery({
    queryFn: fetchProducts,
    cacheTime: 0,
  });

  const [selectedProducts, setSelectedProducts] = useState<CartItemType[]>([]);

  const { data: contract, isLoading: loadingContract } = useQuery({
    queryFn: fetchContract,
    cacheTime: 0,
    enabled: !!id,
    queryKey: ["contract", id],
    onSuccess: (data) => {
      console.log(data);
      setSelectedProducts(data?.subscriptions?.[0]?.products ?? []);
    },
  });

  const mutation = useMutation(putContract, {
    mutationKey: ["contract", id],
    onSuccess: () => {
      console.log("success");
    },
    onError: () => {
      console.log("error");
    },
  });

  if (loadingProducts || loadingContract)
    return (
      <Spinner animation="border" role="status" className="d-block mx-auto">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );

  //une products com os produtos da assinatura
  const contractProducts = products.map((product: any) => {
    const selectedProduct = selectedProducts.find((p) => p.id === product.id);
    return {
      ...product,
      isEnabled: !!selectedProduct,
    };
  });

  console.log(selectedProducts);

  return (
    <div className="pt-5 mb-9">
      <Section small className="py-0">
        <PageBreadcrumb items={assinaturaBreadcrumbItems(id)} />
        <h2 className="mb-6">Assinatura</h2>
        <Row className="g-5">
          <Col>
            <AssinaturaTable
              products={contractProducts}
              addProduct={(product: CartItemType) => {
                setSelectedProducts([...selectedProducts, product]);
              }}
              removeProduct={(product: CartItemType) => {
                const filtered = selectedProducts.filter(
                  (p) => p.id !== product.id
                );
                setSelectedProducts(filtered);
              }}
            />
          </Col>
          {/* <Col xs={12} lg={4}>
            <ResumoCard />
          </Col> */}
        </Row>
        <Col xs={12} className="mt-5 d-flex justify-content-end gap-3">
          <Button
            variant="phoenix-secondary"
            className="text-nowrap"
            type="button"
            as={Link}
            to={id ? `/cliente/${id}` : "/clientes"}
          >
            Sair sem salvar
          </Button>
          <Button
            variant="primary"
            className="px-8 px-sm-11 me-2"
            onClick={() =>
              mutation.mutate({
                cnpj: id!,
                data: {
                  products: selectedProducts.map((p) => {
                    return { id: p.id };
                  }),
                  dueDay: 1,
                },
              })
            }
            loading={mutation.isLoading}
            loadingPosition="end"
          >
            Salvar
          </Button>
        </Col>
      </Section>
    </div>
  );
}
