import TwoFAForm from "components/modules/auth/TwoFAForm";
import AuthSimpleLayout from "layouts/AuthSimpleLayout";

const TwoFA = () => {
  return (
    <AuthSimpleLayout className="col-xxl-4">
      <TwoFAForm layout="simple" />
    </AuthSimpleLayout>
  );
};

export default TwoFA;
