import ForgotPasswordConfirmForm from "components/modules/auth/ForgotPasswordConfirmForm";
import AuthSimpleLayout from "layouts/AuthSimpleLayout";

const ForgotPasswordConfirm = () => {
  return (
    <AuthSimpleLayout className="col-xxl-4">
      <ForgotPasswordConfirmForm layout="simple" />
    </AuthSimpleLayout>
  );
};

export default ForgotPasswordConfirm;
