import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export type Cliente = {
  razaoSocial: string;
  cnpj: string;
  lastRenew: string;
  nextRenew: string;
};

export const clientesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: "Revenda",
    url: "#!",
  },
  {
    label: "Clientes",
    active: true,
  },
];

export const clienteBreadcrumbItems = (cnpj?: string): PageBreadcrumbItem[] => [
  {
    label: "Revenda",
    url: "#!",
  },
  {
    label: "Clientes",
    url: "/",
  },
  {
    label: cnpj ?? "Novo",
    active: true,
  },
];
