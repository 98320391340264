import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axios from "axios";
import RevealDropdown, {
  RevealDropdownTrigger,
} from "components/base/RevealDropdown";
import Scrollbar from "components/base/Scrollbar";
import ActionDropdownItems from "components/common/ActionDropdownItems";
import { CartItemType } from "data/assinatura";
import {
  CriarProdutoDtoType,
  EmpresaType,
  ProdutoEmpresaType,
} from "data/produto";
import { currencyFormat } from "helpers/utils";
import { ChangeEvent, useEffect } from "react";
import { Form, Table } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";

interface ProdutoEmpresaPrecosTableProps {}

const fetchEmpresas = async () => {
  const { data } = await axios.get("/api/empresa");
  return data;
};

const RegraFiscalEmpresaTable = ({}: ProdutoEmpresaPrecosTableProps) => {
  const empresasQuery = useQuery<EmpresaType[]>({
    queryKey: ["empresas"],
    queryFn: fetchEmpresas,
  });

  const { control, setValue } = useFormContext<CriarProdutoDtoType>();

  const { fields } = useFieldArray({
    control,
    name: "empresas",
    rules: { required: true },
  });

  useEffect(() => {
    if (empresasQuery.isSuccess) {
      setValue(
        "empresas",
        empresasQuery.data.map((e) => ({
          idEmpresa: e.id,
          desconto: 0,
          descontoMaximo: 0,
          estoqueMinimo: 0,
          idRegraFiscal: 0,
          margem: 0,
          precoAberto: false,
          precoCompra: 0,
          valor: 0,
        }))
      );
    }
  }, [empresasQuery.isSuccess]);

  return (
    <Scrollbar autoHeight autoHeightMax="100%" className="table-scrollbar">
      <Table className="phoenix-table fs-9 mb-0 border-top border-translucent">
        <thead>
          <tr>
            <th scope="col" style={{ width: 100 }}>
              Empresa
            </th>
            <th className="ps-5 text-center" scope="col">
              CST
            </th>
            <th className="ps-5 text-center" scope="col">
              Alíquota
            </th>
            <th className="ps-5 text-center" scope="col">
              NCM
            </th>
            <th className="ps-5 text-center" scope="col">
              Cest
            </th>
            <th className="ps-5 text-center" scope="col">
              CFOP
            </th>
            <th className="ps-5 text-center" scope="col">
              Ação
            </th>
          </tr>
        </thead>
        {empresasQuery.isSuccess && empresasQuery.data.length > 0 && (
          <tbody className="list" id="cart-table-body">
            {fields.map((p, i) => (
              <RegraFiscalEmpresaRow
                key={p.id}
                index={i}
                empresa={empresasQuery?.data?.[i]}
              />
            ))}
          </tbody>
        )}
      </Table>
    </Scrollbar>
  );
};

const RegraFiscalEmpresaRow = ({
  empresa: { nomeFantasia },
  index,
}: {
  empresa: EmpresaType;
  index: number;
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CriarProdutoDtoType>();

  return (
    <tr className="cart-table-row">
      <td>
        <Link className="fw-semibold line-clamp-2" to="#!">
          {nomeFantasia}
        </Link>
      </td>
      <td className="fs-8 ps-5 text-center"></td>

      <td className="fs-8 ps-5 text-center"></td>
      <td className="fs-8 ps-5 text-center"></td>
      <td className="fs-8 ps-5 text-center"></td>
      <td className="fs-8 ps-5 text-center"></td>
      <td className="fs-8 ps-5 text-center">
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownItems />
          </RevealDropdown>
        </RevealDropdownTrigger>
      </td>
    </tr>
  );
};

export default RegraFiscalEmpresaTable;
