import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Button from "components/base/Button";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import SearchBox from "components/common/SearchBox";
import ClienteTable, {
  clienteTablecolumns,
} from "components/tables/ClienteTable";
import { clientesBreadcrumbItems } from "data/cliente";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

const fetchUser = async () => {
  const { data } = await axios.get(
    process.env.REACT_APP_API_CUSTOMER_URL + "/customer"
  );
  return data;
};

const Clientes = () => {
  const { data, error, isLoading } = useQuery("customers", fetchUser);

  const table = useAdvanceTable({
    data: data,
    columns: clienteTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
  });

  if (isLoading)
    return (
      <Spinner animation="border" role="status" className="d-block mx-auto">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={clientesBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Clientes</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Buscar"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="primary" as={Link} to="/cliente">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Cadastrar
                </Button>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <ClienteTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Clientes;
