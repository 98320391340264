import avatar from "assets/img/team/72x72/57.webp";
import classNames from "classnames";
import Avatar from "components/base/Avatar";
import Scrollbar from "components/base/Scrollbar";
import FeatherIcon from "feather-icons-react";
import { useAuth } from "providers/AuthProvider";
import { useState } from "react";
import { Card, Dropdown, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const { user, logout } = useAuth();
  const [navItems] = useState([
    {
      label: "Perfil",
      icon: "user",
    },
    {
      label: "Configurações",
      icon: "settings",
    },
    {
      label: "Central de Ajuda",
      icon: "help-circle",
    },
  ]);

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        "navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border"
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar variant="name" src={avatar} size="xl">
              {user?.name?.charAt(0)}
            </Avatar>
            <h6 className="text-body-emphasis">{user?.name}</h6>
          </div>
          <div style={{ height: "10rem" }}>
            <Scrollbar>
              <Nav className="nav flex-column mb-2 pb-1">
                {navItems.map((item) => (
                  <Nav.Item key={item.label}>
                    <Nav.Link href="#!" className="px-3">
                      <FeatherIcon
                        icon={item.icon}
                        size={16}
                        className="me-2 text-body"
                      />
                      <span className="text-body-highlight">{item.label}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Scrollbar>
          </div>
        </Card.Body>
        <Card.Footer className="p-0 border-top border-translucent">
          {/* <Nav className="nav flex-column my-3">
            <Nav.Item>
              <Nav.Link href="#!" className="px-3">
                <FeatherIcon
                  icon="user-plus"
                  size={16}
                  className="me-2 text-body"
                />
                <span>Add another account</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <hr /> */}
          <div className="px-3">
            <Link
              onClick={() => logout()}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
              to={"/pages/authentication/simple/sign-in"}
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Sair
            </Link>
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">
            <Link className="text-body-quaternary me-1" to="#!">
              Política de Privacidade
            </Link>
            •
            <Link className="text-body-quaternary mx-1" to="#!">
              Termos de Serviço
            </Link>
            •
            <Link className="text-body-quaternary ms-1" to="#!">
              Cookies
            </Link>
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
