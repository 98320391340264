import defaultDark from "assets/img/generic/default-dark.png";
import defaultLight from "assets/img/generic/default-light.png";
import verticalDarker from "assets/img/generic/vertical-darker.png";
import verticalLighter from "assets/img/generic/vertical-lighter.png";
import WarningMessage from "components/common/WarningMessage";
import { NavbarAppearanceVariant } from "config";
import { useAppContext } from "providers/AppProvider";
import { useSettingsPanelContext } from "providers/SettingsPanelProvider";
import { ChangeEvent } from "react";
import { Col, Row } from "react-bootstrap";
import RadioItem from "./RadioItem";

const VerticalNavbarAppearance = () => {
  const {
    config: { isDark, navbarVerticalAppearance },
    setConfig,
  } = useAppContext();

  const {
    settingsPanelConfig: { disableVerticalNavbarAppearance },
  } = useSettingsPanelContext();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setConfig({
      navbarVerticalAppearance: value as NavbarAppearanceVariant,
    });
  };

  return (
    <div className="setting-panel-item">
      <h5 className="setting-panel-item-title">Menu Vertical</h5>
      <Row className="gx-2">
        <Col xs={6}>
          <RadioItem
            label="Padrão"
            name="vertical-navbar-appearance"
            value="default"
            thumb={isDark === false ? defaultLight : defaultDark}
            checked={navbarVerticalAppearance === "default"}
            handleChange={handleChange}
            disabled={disableVerticalNavbarAppearance}
          />
        </Col>
        <Col xs={6}>
          <RadioItem
            label={isDark === false ? "Escuro" : "Claro"}
            name="vertical-navbar-appearance"
            value="darker"
            thumb={isDark === false ? verticalDarker : verticalLighter}
            checked={navbarVerticalAppearance === "darker"}
            handleChange={handleChange}
            disabled={disableVerticalNavbarAppearance}
          />
        </Col>
      </Row>
      {disableVerticalNavbarAppearance && (
        <WarningMessage message="Você não pode alterar no momento" />
      )}
    </div>
  );
};

export default VerticalNavbarAppearance;
