import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export const servidoresBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: "Overwatch",
    url: "#!",
  },
  {
    label: "Servidores",
    active: true,
  },
];
