import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axios from "axios";
import FilterButtonGroup, {
  FilterMenu,
} from "components/common/FilterButtonGroup";
import {
  CategoriaType,
  DepartamentoType,
  EmpresaType,
  FabricanteType,
} from "data/produto";
import { Placeholder } from "react-bootstrap";

const fetchEmpresas = async () => {
  const { data } = await axios.get("/api/empresa");
  return data;
};

const fetchDepartamentos = async () => {
  const { data } = await axios.get("/api/departamento");
  return data;
};

const fetchCategorias = async () => {
  const { data } = await axios.get("/api/categoria");
  return data;
};

const fetchFabricantes = async () => {
  const { data } = await axios.get("/api/fabricante");
  return data;
};

export interface Filter {
  empresa?: number;
  departamento?: number;
  categoria?: number;
  fabricante?: number;
}

export default function Menus({
  filters,
  onFilter,
}: {
  filters: Filter;
  onFilter: (filter: Filter) => void;
}) {
  const empresasQuery = useQuery<EmpresaType[]>({
    queryKey: ["empresas"],
    queryFn: fetchEmpresas,
    placeholderData: keepPreviousData,
  });

  const departamentosQuery = useQuery<DepartamentoType[]>({
    queryKey: ["departamentos"],
    queryFn: fetchDepartamentos,
    placeholderData: keepPreviousData,
  });

  const categoriasQuery = useQuery<CategoriaType[]>({
    queryKey: ["categorias"],
    queryFn: fetchCategorias,
    placeholderData: keepPreviousData,
  });

  const fabricantesQuery = useQuery<FabricanteType[]>({
    queryKey: ["fabricantes"],
    queryFn: fetchFabricantes,
    placeholderData: keepPreviousData,
  });

  const empresas =
    empresasQuery.data?.map((d) => {
      return {
        label: d.nomeFantasia,
        active: filters.empresa === d.id,
        onClick: () => onFilter({ ...filters, empresa: d.id }),
      };
    }) ?? [];

  const departamentos =
    departamentosQuery.data?.map((d) => {
      return {
        label: d.descricao,
        active: filters.departamento === d.id,
        onClick: () => onFilter({ ...filters, departamento: d.id }),
      };
    }) ?? [];

  const categorias =
    categoriasQuery.data?.map((c) => {
      return {
        label: c.descricao,
        active: filters.categoria === c.id,
        onClick: () => onFilter({ ...filters, categoria: c.id }),
      };
    }) ?? [];

  const fabricantes =
    fabricantesQuery.data?.map((f) => {
      return {
        label: f.descricao,
        active: filters.fabricante === f.id,
        onClick: () => onFilter({ ...filters, fabricante: f.id }),
      };
    }) ?? [];

  const filterMenus: FilterMenu[] = [
    {
      label: "Empresa",
      items: [
        {
          label: "Todas",
          active: filters.empresa === undefined,
          onClick: () => onFilter({ empresa: undefined }),
        },
        ...empresas,
      ],
    },
    {
      label: "Departamento",
      items: [
        {
          label: "Todos",
          active: filters.departamento === undefined,
          onClick: () => onFilter({ departamento: undefined }),
        },
        ...departamentos,
      ],
    },
    {
      label: "Categoria",
      items: [
        {
          label: "Todas",
          active: filters.categoria === undefined,
          onClick: () => onFilter({ categoria: undefined }),
        },
        ...categorias,
      ],
    },
    {
      label: "Laboratório",
      items: [
        {
          label: "Todos",
          active: filters.fabricante === undefined,
          onClick: () => onFilter({ fabricante: undefined }),
        },
        ...fabricantes,
      ],
    },
  ];

  if (departamentosQuery.isLoading) {
    return (
      <Placeholder xs={4} animation="wave" size="lg" as="div" className="mt-1">
        <Placeholder xs={12} size="lg" />
      </Placeholder>
    );
  }

  return (
    <div className="scrollbar overflow-hidden-y">
      <FilterButtonGroup menus={filterMenus} />
    </div>
  );
}
