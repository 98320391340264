import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, {
  RevealDropdownTrigger,
} from "components/base/RevealDropdown";
import ActionDropdownItems from "components/common/ActionDropdownItems";
import { ProdutoTableType } from "data/produto";
import { currencyFormat } from "helpers/utils";
import { Link } from "react-router-dom";

export const produtoTableColumns: ColumnDef<ProdutoTableType>[] = [
  {
    accessorKey: "codigo",
    header: "Código",
    meta: {
      headerProps: { style: { width: 150 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    accessorKey: "descricao",
    header: "Descrição",
    cell: ({ row: { original } }) => {
      const { descricao } = original;
      return (
        <Link
          to="/apps/e-commerce/customer/product-details"
          className="fw-semibold line-clamp-3"
        >
          {descricao}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: 350 }, className: "ps-4" },
      cellProps: { className: "ps-4" },
    },
  },
  {
    accessorKey: "departamento",
    header: "Departamento",
    meta: {
      headerProps: { style: { width: 150 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    accessorKey: "principioAtivo",
    header: "Princípio Ativo",
    meta: {
      headerProps: { style: { width: 150 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    id: "categoria",
    accessorFn: ({ categorias }) => categorias.join(""),
    header: "Categoria",
    cell: ({ row: { original } }) => {
      const { categorias } = original;
      return (
        <div className="d-flex flex-wrap gap-2">
          {categorias.map((categoria) => (
            <Link key={categoria} to="#!" className="text-decoration-none">
              <Badge variant="tag">{categoria}</Badge>
            </Link>
          ))}
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: 250 }, className: "ps-3" },
      cellProps: { style: { minWidth: 225 }, className: "ps-3" },
    },
  },
  {
    accessorKey: "fabricante",
    header: "Laboratório",
    cell: ({ row: { original } }) => {
      const { fabricante } = original;
      return <Link to="#!">{fabricante}</Link>;
    },
    meta: {
      headerProps: { style: { width: 200 }, className: "ps-4" },
      cellProps: { className: "ps-4 fw-semibold text-start" },
    },
  },
  {
    accessorKey: "estoque",
    header: "Estoque",
    meta: {
      headerProps: { style: { width: 150 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    id: "action",
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown>
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: "7%" } },
      cellProps: { className: "text-end" },
    },
  },
];

const ProdutoTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination showViewAllBtn={false} />
    </div>
  );
};

export default ProdutoTable;
