import classNames from "classnames";
import NavbarBrand from "components/navbars/nav-items/NavbarBrand";
import NavItems from "components/navbars/nav-items/NavItems";
import NavItemsSlim from "components/navbars/nav-items/NavItemsSlim";
import { useAppContext } from "providers/AppProvider";
import { useBreakpoints } from "providers/BreakpointsProvider";
import { Navbar } from "react-bootstrap";

const NavbarTopDefault = () => {
  const {
    config: { navbarTopShape, navbarTopAppearance },
  } = useAppContext();

  const { breakpoints } = useBreakpoints();

  return (
    <Navbar
      className={classNames("navbar-top fixed-top", {
        "navbar-slim": navbarTopShape === "slim",
        // 'navbar-darker': navbarTopAppearance === 'darker'
      })}
      expand
      variant=""
      data-navbar-appearance={navbarTopAppearance === "darker" ? "darker" : ""}
    >
      <div className="navbar-collapse justify-content-between">
        <NavbarBrand />

        {navbarTopShape === "default" ? (
          <>
            <NavItems />
          </>
        ) : (
          <NavItemsSlim />
        )}
      </div>
    </Navbar>
  );
};

export default NavbarTopDefault;
