import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  Icon,
  UilBag,
  UilChartPie,
  UilPuzzlePiece,
} from "@iconscout/react-unicons";

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: "font-awesome" | "feather" | "unicons";
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  next?: boolean;
  roles?: string[];
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
  roles?: string[];
}

export const routes: RouteItems[] = [
  {
    label: "Revenda",
    horizontalNavLabel: "Revenda",
    active: true,
    icon: UilBag,
    labelDisabled: true,
    pages: [
      {
        name: "Revenda",
        icon: "briefcase",
        active: true,
        flat: true,
        hasNew: false,
        pages: [
          {
            name: "clientes",
            path: "/",
            pathName: "clientes",
            topNavIcon: "shopping-cart",
            active: true,
          },
          {
            name: "licença",
            path: "/licenca",
            pathName: "licenca",
            topNavIcon: "shopping-cart",
            active: true,
          },
        ],
      },
    ],
  },
  {
    label: "Overwatch",
    horizontalNavLabel: "Overwatch",
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    roles: ["admin"],
    pages: [
      {
        name: "Overwatch",
        icon: "monitor",
        active: true,
        flat: true,
        hasNew: true,
        pages: [
          {
            name: "logs",
            path: "/logs",
            pathName: "logs",
            topNavIcon: "shopping-cart",
            active: true,
          },
          {
            name: "servidores",
            path: "/servidores",
            pathName: "servidores",
            topNavIcon: "shopping-cart",
            active: true,
          },
        ],
      },
    ],
  },
  {
    label: "retaguarda",
    active: true,
    icon: UilPuzzlePiece,
    megaMenu: true, // works for navbar top
    pages: [
      {
        name: "Cadastros",
        icon: "file-text",
        active: true,
        flat: true,
        pages: [
          {
            name: "empresas",
            path: "/retaguarda/cadastros/empresas",
            pathName: "empresas",
            topNavIcon: "shopping-cart",
            active: true,
          },
          {
            name: "produtos",
            path: "/retaguarda/cadastros/produtos",
            pathName: "produtos",
            topNavIcon: "shopping-cart",
            active: true,
          },
          {
            name: "departamentos",
            path: "/retaguarda/cadastros/departamentos",
            pathName: "departamentos",
            topNavIcon: "shopping-cart",
            active: true,
          },
          {
            name: "fabricantes",
            path: "/retaguarda/cadastros/fabricantes",
            pathName: "fabricantes",
            topNavIcon: "shopping-cart",
            active: true,
          },
          {
            name: "clientes",
            path: "/retaguarda/cadastros/clientes",
            pathName: "clientes",
            topNavIcon: "shopping-cart",
            active: true,
          },
        ],
      },
    ],
  },
];
