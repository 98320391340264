import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export type CartItemType = {
  id: number | string;
  name: string;
  isEnabled: boolean;
};

export const assinaturaBreadcrumbItems = (
  cnpj?: string
): PageBreadcrumbItem[] => [
  {
    label: "Revenda",
    url: "#!",
  },
  {
    label: "Clientes",
    url: "/",
  },
  {
    label: cnpj ?? "Novo",
    url: cnpj ? `/cliente/${cnpj}` : "#!",
  },
  {
    label: "Assinatura",
    active: true,
  },
];
