import axios from "axios";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface AuthContextInterFace {
  user: {
    email: string;
    name: string;
    newPasswordRequired: boolean;
    twoFactorRequired: boolean;
    session: string;
    roles: string[];
  };
  setUser: (user: { name: string; email: string }) => void;
  logout: () => Promise<void>;
  setNewPassword: (email: string, session: string) => void;
}

export const AuthContext = createContext({} as AuthContextInterFace);

axios.defaults.withCredentials = true;

//response interceptor 403 to /login
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 403) {
      window.location.href = "/pages/errors/403";
    }
    return Promise.reject(error);
  }
);

//response interceptor 401 to /login
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const from = window.location.pathname;
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== "/pages/authentication/simple/sign-in"
    ) {
      window.location.href = `/pages/authentication/simple/sign-in?from=${from}`;
    }
    return Promise.reject(error);
  }
);

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get("/api/user/me");
        const { user } = response.data;
        if (user) {
          setUser(user);
          const from = location.pathname;
          navigate(from ? from : "/");
        }
      } catch (error) {
        console.error("User is not authenticated", error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    checkAuth();
  }, []);

  const logout = async () => {
    await axios.post("/api/user/logout");
    setUser(null);
    navigate("/pages/authentication/simple/sign-in");
  };

  const setNewPassword = (email: string, session: string) => {
    setUser((prev: any) => ({
      ...prev,
      newPasswordRequired: true,
      session,
      email,
    }));
  };

  return (
    <AuthContext.Provider value={{ user, logout, setNewPassword, setUser }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
