import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement } from "react";
import Select from "react-select/creatable";

import { CreatableAdditionalProps } from "react-select/dist/declarations/src/useCreatable";

import {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  MenuListProps,
} from "react-select";
import { FixedSizeList } from "react-window";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

const ClearIndicator = (props: ClearIndicatorProps) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} className="me-2">
      <div className="text-primary fs-9">limpar</div>
    </div>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faAngleDown} className="fs-9 text-body" />
    </components.DropdownIndicator>
  );
};

const MenuList = ({ children, ...rest }: MenuListProps) => {
  return (
    <FixedSizeList
      height={150}
      itemCount={(children as any)?.length ?? 0}
      itemSize={30}
      width="100%"
    >
      {({ index, style }) => (
        <div style={style}>{(children as any)?.[index]}</div>
      )}
    </FixedSizeList>
  );
};

const AsyncReactSelect = ({
  icon,
  ...rest
}: StateManagerProps &
  CreatableAdditionalProps<any, any> & { icon?: ReactElement }) => {
  return (
    <div className="react-select-container">
      <Select
        closeMenuOnSelect={true}
        createOptionPosition="first"
        formatCreateLabel={(inputValue) => `Criar "${inputValue}"`}
        components={{
          ClearIndicator,
          DropdownIndicator,
          MenuList: MenuList,
        }}
        classNamePrefix="react-select"
        classNames={{
          control: () => (icon ? "ps-5" : ""),
          placeholder: () => (icon ? "ps-2" : ""),
        }}
        {...rest}
      />
      {icon}
    </div>
  );
};

export default AsyncReactSelect;
