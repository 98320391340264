import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import AsyncReactSelect from "components/base/AsyncReactSelect";
import ReactSelect from "components/base/ReactSelect";
import {
  CategoriaType,
  CriarProdutoDtoType,
  DepartamentoType,
  FabricanteType,
  PrincipioAtivoType,
} from "data/produto";
import { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";

const fetchDepartamentos = async () => {
  const { data } = await axios.get("/api/departamento");
  return data;
};

const fetchCategorias = async () => {
  const { data } = await axios.get("/api/categoria");
  return data;
};

const fetchFabricantes = async () => {
  const { data } = await axios.get("/api/fabricante");
  return data;
};

const fetchPrincipioAtivo = async () => {
  const { data } = await axios.get("/api/principioAtivo");
  return data;
};

const OrganizeFormCard = ({ className }: { className?: string }) => {
  const departamentosQuery = useQuery<DepartamentoType[]>({
    queryKey: ["departamentos"],
    queryFn: fetchDepartamentos,
  });

  const departamentoMutation = useMutation<any, any, { descricao: string }>({
    mutationFn: (d: { descricao: string }) => {
      return axios.post("/api/departamento", d);
    },
  });

  const fabricantesQuery = useQuery<FabricanteType[]>({
    queryKey: ["fabricantes"],
    queryFn: fetchFabricantes,
  });

  const fabricanteMutation = useMutation<any, any, { descricao: string }>({
    mutationFn: (d: { descricao: string }) => {
      return axios.post("/api/fabricante", d);
    },
  });

  const categoriasQuery = useQuery<CategoriaType[]>({
    queryKey: ["categorias"],
    queryFn: fetchCategorias,
  });

  const categoriaMutation = useMutation<any, any, { descricao: string }>({
    mutationFn: (d: { descricao: string }) => {
      return axios.post("/api/categoria", d);
    },
  });

  const principioAtivoQuery = useQuery<PrincipioAtivoType[]>({
    queryKey: ["principioAtivo"],
    queryFn: fetchPrincipioAtivo,
  });

  const principioAtivoMutation = useMutation<any, any, { descricao: string }>({
    mutationFn: (d: { descricao: string }) => {
      return axios.post("/api/principioAtivo", d);
    },
  });

  const { watch, setValue } = useFormContext<CriarProdutoDtoType>();

  const departamentoSelecionado = departamentosQuery?.data
    ?.map((e) => ({ label: e.descricao, value: e.id }))
    .find((d) => d.value == watch("idDepartamento"));

  const fabricanteSelecionado = fabricantesQuery?.data
    ?.map((e) => ({ label: e.descricao, value: e.id }))
    .find((d) => d.value == watch("idFabricante"));

  const categoriasSelecionadas = categoriasQuery?.data
    ?.map((e) => ({ label: e.descricao, value: e.id }))
    .filter((d) => watch("idsCategorias")?.includes(d.value));

  const principioAtivoSelecionado = principioAtivoQuery?.data
    ?.map((e) => ({ label: e.descricao, value: e.id }))
    .find((d) => d.value == watch("idPrincipioAtivo"));

  return (
    <Card className={className}>
      <Card.Body>
        <h4 className="mb-4">Organização</h4>
        <Row className="gx-3 gy-4">
          <Col xs={12} sm={6} xl={12}>
            <div className="d-flex gap-2 mb-2">
              <h5 className="mb-0 text-body-highlight">Departamento</h5>
            </div>
            <AsyncReactSelect
              isClearable
              onCreateOption={(inputValue) => {
                departamentoMutation
                  .mutateAsync({ descricao: inputValue })
                  .then((e) => {
                    departamentosQuery.refetch();
                    setValue("idDepartamento", e.data.id);
                  });
              }}
              value={departamentoSelecionado}
              onChange={(value: any) => {
                setValue("idDepartamento", value?.value);
              }}
              options={departamentosQuery.data?.map((d) => ({
                value: d.id,
                label: d.descricao,
              }))}
              placeholder="Selecionar departamento"
            />
          </Col>
          <Col xs={12} sm={6} xl={12}>
            <div className="d-flex gap-2 mb-2">
              <h5 className="mb-0 text-body-highlight">Laboratório</h5>
            </div>
            <AsyncReactSelect
              isClearable
              onCreateOption={(inputValue) => {
                fabricanteMutation
                  .mutateAsync({ descricao: inputValue })
                  .then((e) => {
                    fabricantesQuery.refetch();
                    setValue("idFabricante", e.data.id);
                  });
              }}
              value={fabricanteSelecionado}
              onChange={(value: any) => {
                setValue("idFabricante", value?.value);
              }}
              options={fabricantesQuery.data?.map((d) => ({
                value: d.id,
                label: d.descricao,
              }))}
              placeholder="Selecionar fabricante"
            />
          </Col>
          <Col xs={12} sm={6} xl={12}>
            <div className="d-flex gap-2 mb-2">
              <h5 className="mb-0 text-body-highlight">Princípio Ativo</h5>
            </div>
            <AsyncReactSelect
              isClearable
              onCreateOption={(inputValue) => {
                principioAtivoMutation
                  .mutateAsync({ descricao: inputValue })
                  .then((e) => {
                    principioAtivoQuery.refetch();
                    setValue("idPrincipioAtivo", e.data.id);
                  });
              }}
              value={principioAtivoSelecionado}
              onChange={(value: any) => {
                setValue("idPrincipioAtivo", value?.value);
              }}
              options={principioAtivoQuery.data?.map((d) => ({
                value: d.id,
                label: d.descricao,
              }))}
              placeholder="Selecionar princípio ativo"
            />
          </Col>
          <Col xs={12} sm={6} xl={12}>
            <div className="d-flex gap-2 mb-2">
              <h5 className="mb-0 text-body-highlight">Categorias</h5>
              {/* <Link className="fw-bold fs-9" to="#!">
                Nova categoria
              </Link> */}
            </div>
            <AsyncReactSelect
              isClearable
              isMulti
              onCreateOption={(inputValue) => {
                categoriaMutation
                  .mutateAsync({ descricao: inputValue })
                  .then((e) => {
                    categoriasQuery.refetch();
                    setValue("idsCategorias", [
                      ...(watch("idsCategorias") || []),
                      e.data.id,
                    ]);
                  });
              }}
              value={categoriasSelecionadas}
              onChange={(value: any) => {
                setValue("idsCategorias", value?.map((v: any) => v.value));
              }}
              options={categoriasQuery.data?.map((d) => ({
                value: d.id,
                label: d.descricao,
              }))}
              placeholder="Selecionar categorias"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default OrganizeFormCard;
