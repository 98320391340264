import Button from "components/base/Button";
import Dropzone from "components/base/Dropzone";
import TinymceEditor from "components/base/TinymceEditor";
import OrganizeFormCard from "components/cards/OrganizeFormCard";
import VariantFormCard from "components/cards/VariantFormCard";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import InventoryTab from "components/tabs/InventoryTab";
import { produtoBreadcrumbItems } from "data/produto";
import { Col, Form, Row } from "react-bootstrap";

const EditarProduto = () => {
  return (
    <div>
      <PageBreadcrumb items={produtoBreadcrumbItems()} />
      <form className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Cadastrar um produto</h2>
            <h5 className="text-body-tertiary fw-semibold">
              Preencha as informações do produto
            </h5>
          </div>
          <div className="d-flex flex-wrap gap-2">
            <Button variant="phoenix-secondary" type="button">
              Descartar
            </Button>
            <Button variant="phoenix-primary" type="button">
              Salvar rascunho
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </div>
        </div>
        <Row className="g-5">
          <Col xs={12} xl={8}>
            <h4 className="mb-3">Identificação do produto</h4>
            <Row className="g-2">
              <Col xs={2} xl={2}>
                <Form.Control placeholder="Código" className="mb-5" disabled />
              </Col>
              <Col xs={6} xl={6}>
                <Form.Control
                  placeholder="Descrição do produto"
                  className="mb-5"
                />
              </Col>
              <Col xs={4} xl={4}>
                <Form.Control placeholder="Código de Barras" className="mb-5" />
              </Col>
            </Row>
            <div>
              <h4 className="mb-3">Inventário</h4>
              <InventoryTab />
            </div>
          </Col>
          <Col xs={12} xl={4}>
            <Row className="g-2">
              <Col xs={12} xl={12}>
                <OrganizeFormCard className="mb-3" />
              </Col>
              <Col xs={12} xl={12}>
                <VariantFormCard />
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default EditarProduto;
