import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";
import SearchBox from "components/common/SearchBox";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import { useAuth } from "providers/AuthProvider";
import React, { useEffect } from "react";
import Badge from "react-bootstrap/Badge";
import { FixedSizeList as List } from "react-window";
import styled from "styled-components";

const Table = styled.table`
  table,
  tr td {
  }
  tbody {
    display: block;
    overflow: hidden;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  thead {
    border-radius: 8px;
    min-height: 38px;
  }
  table {
    width: 100%;
  }
`;

const Row = ({ index, style, data, onMessageClick }: any) => {
  const log = data[index];

  return (
    <tr
      style={{
        ...style,
        backgroundColor:
          log.level === 4 || log.level === 5
            ? "rgba(255, 0, 0, 0.2)"
            : log.level === 3
            ? "rgba(255, 255, 0, 0.2)"
            : "rgba(0, 0, 255, 0.2)",
      }}
      className="w-100"
    >
      <td style={{ width: "5%" }}>{log.tipo}</td>
      <td style={{ width: "15%" }}>{log.empresa?.cnpj}</td>
      <td style={{ width: "25%" }}>{log.empresa?.razaoSocial}</td>
      <td style={{ width: "15%" }}>{log.horario}</td>
      <td
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "40%",
          cursor: "pointer",
        }}
        onClick={() => {
          const clickMessage = log.stackTrace
            ? log.mensagem + " - " + log.stackTrace
            : log.mensagem;
          onMessageClick(clickMessage);
        }}
      >
        {log.mensagem}
      </td>
    </tr>
  );
};
export default function Logs() {
  const [logs, setLogs] = React.useState<
    { level: number; stackTrace: string; mensagem: string; empresa: any }[]
  >([]);
  const [connections, setConnections] = React.useState<any>(undefined);
  const listRef = React.useRef<List>(null);
  const isAtEndOfListRef = React.useRef(true);
  const [filteredLogs, setFilteredLogs] = React.useState(logs);
  const [selectedLevels, setSelectedLevels] = React.useState<number[]>([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const { user } = useAuth();

  const filteredLogsBySearch = filteredLogs?.filter(
    (log) =>
      log.empresa?.razaoSocial
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      log.empresa?.cnpj?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      log.mensagem?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      log.stackTrace?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleScroll = ({
    scrollOffset,
    scrollUpdateWasRequested,
  }: {
    scrollOffset: number;
    scrollUpdateWasRequested: boolean;
  }) => {
    if (!scrollUpdateWasRequested) {
      const isAtEnd = scrollOffset >= (logs?.length ?? 1) * 50 - 550;
      isAtEndOfListRef.current = isAtEnd;
    }
  };

  useEffect(() => {
    const clients = [
      {
        url: "ws/BackendHub",
        icon: <FeatherIcon icon="server" />,
      },
      {
        url: "ws/FrontendHub",
        icon: <FeatherIcon icon="monitor" />,
      },
    ];
    let openConnetcions = [] as any[];
    clients.forEach((e) => {
      const c = new HubConnectionBuilder()
        .withUrl(e.url, {
          transport: HttpTransportType.WebSockets,
        })
        .build();
      c.onclose(() => {
        setTimeout(() => {
          c.start()
            .then(() => {})
            .catch(() => {});
        }, 5000);
      });
      c.on("Log", (registro: any) => {
        const newLog = {
          tipo: e.icon,
          level: registro.logLevel,
          stackTrace: registro.stackTrace,
          mensagem: registro.mensagem,
          empresa: registro.backend ?? registro.frontend,
          horario: moment(registro.horario).format("DD/MM - HH:mm:ss"),
        };

        setLogs((prevLogs) => {
          const newLogs = [...prevLogs, newLog];
          return newLogs;
        });
      });
      c.start().then(() => {
        c.invoke("Logar")
          .then(() => {})
          .catch((err: any) => {
            console.error(err);
          });
      });
      openConnetcions.push(c);
    });
    setConnections(openConnetcions);
    return () => {
      connections?.forEach((e: any) => e.stop());
    };
  }, []);

  const handleMessageClick = (message: string) => {
    alert(message);
  };

  const handleBadgeClick = (levels: number[]) => {
    if (
      JSON.stringify(levels.sort()) === JSON.stringify(selectedLevels.sort())
    ) {
      setSelectedLevels([]); // Desseleciona a badge
      setFilteredLogs(logs); // Mostra todos os logs novamente
    } else {
      setSelectedLevels(levels); // Seleciona a nova badge
      const newFilteredLogs = logs.filter((log) => levels.includes(log.level));
      setFilteredLogs(newFilteredLogs);
    }
  };

  useEffect(() => {
    const newFilteredLogs = logs.filter(
      (log) => selectedLevels.includes(log.level) || selectedLevels.length === 0
    );
    setFilteredLogs(newFilteredLogs);

    if (listRef.current !== null && isAtEndOfListRef.current) {
      setTimeout(() => {
        if (newFilteredLogs.length > 0) {
          listRef.current?.scrollToItem(newFilteredLogs.length - 1, "end");
        }
      }, 0);
    }
  }, [logs, selectedLevels]);

  return (
    <div className="w-100 justify-content-between">
      <div className="w-100 d-flex justify-content-end m-2 ms-0">
        <SearchBox
          className="w-100"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Badge
          bg=""
          className="d-flex me-2 align-items-center"
          onClick={() => handleBadgeClick([0])}
          style={{
            cursor: "pointer",
            backgroundColor: "rgba(0, 255, 158, 1)",
          }}
        >
          Trace
          <div
            className="ms-2 p-1"
            style={{ backgroundColor: "rgba(117, 117, 117, 0.8)" }}
          >
            {logs.filter((e) => e.level === 0)?.length ?? 0}
          </div>
        </Badge>
        <Badge
          bg=""
          className="d-flex me-2 align-items-center"
          onClick={() => handleBadgeClick([1])}
          style={{
            cursor: "pointer",
            backgroundColor: "rgba(92, 65, 170, 1)",
          }}
        >
          Debug
          <div
            className="ms-2 p-1"
            style={{ backgroundColor: "rgba(117, 117, 117, 0.8)" }}
          >
            {logs.filter((e) => e.level === 1)?.length ?? 0}
          </div>
        </Badge>
        <Badge
          bg="info"
          className="d-flex me-2 align-items-center"
          onClick={() => handleBadgeClick([2])}
          style={{ cursor: "pointer" }}
        >
          Information
          <div
            className="ms-2 p-1"
            style={{ backgroundColor: "rgba(117, 117, 117, 0.8)" }}
          >
            {logs.filter((e) => e.level === 2)?.length ?? 0}
          </div>
        </Badge>
        <Badge
          bg="warning"
          className="d-flex me-2 align-items-center"
          onClick={() => handleBadgeClick([3])}
          style={{ cursor: "pointer" }}
        >
          Warning
          <div
            className="ms-2 p-1"
            style={{ backgroundColor: "rgba(117, 117, 117, 0.8)" }}
          >
            {logs.filter((e) => e.level === 3)?.length ?? 0}
          </div>
        </Badge>
        <Badge
          bg=""
          className="d-flex me-2 align-items-center"
          onClick={() => handleBadgeClick([4, 5])}
          style={{ cursor: "pointer", backgroundColor: "rgba(255, 0, 0)" }}
        >
          Error
          <div
            className="ms-2 p-1"
            style={{ backgroundColor: "rgba(117, 117, 117, 0.8)" }}
          >
            {logs.filter((e) => e.level === 4 || e.level === 5)?.length ?? 0}
          </div>
        </Badge>
      </div>
      <div className="w-100" style={{ width: "400px" }}>
        <Table className="w-100">
          <thead className="w-100">
            <tr>
              <th style={{ width: "5%" }}>Tipo</th>
              <th style={{ width: "15%" }}>CNPJ</th>
              <th style={{ width: "25%" }}>Razão Social</th>
              <th style={{ width: "15%" }}>Horário</th>
              <th style={{ width: "40%" }}>Mensagem</th>
            </tr>
          </thead>
          <tbody className="table-group-divider d-flex">
            <List
              ref={listRef}
              height={550}
              itemCount={filteredLogsBySearch?.length ?? 0}
              itemSize={50}
              width="100%"
              itemData={filteredLogsBySearch}
              style={{
                overflow: "auto",
              }}
              onScroll={handleScroll}
              overscanCount={20}
            >
              {(props: any) => (
                <Row {...props} onMessageClick={handleMessageClick} />
              )}
            </List>
          </tbody>
        </Table>
        <div id="logs"></div>
      </div>
    </div>
  );
}
