import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Button from "components/base/Button";
import OrganizeFormCard from "components/cards/OrganizeFormCard";
import VariantFormCard from "components/cards/VariantFormCard";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import ProdutoEmpresaPrecosTable from "components/tables/ProdutoEmpresaPrecosTable";
import RegraFiscalEmpresaTable from "components/tables/RegraFiscalEmpresaTable";
import InventoryTab from "components/tabs/InventoryTab";
import { CriarProdutoDtoType, produtoBreadcrumbItems } from "data/produto";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

const NovoProduto = () => {
  const form = useForm<CriarProdutoDtoType>({
    defaultValues: {
      descricao: "",
      codigosDeBarras: [],
      controleEspecial: false,
      fracionado: 1,
      idDepartamento: 0,
      idFabricante: 0,
      idPrincipioAtivo: 0,
      inativo: false,
      pmc: 0,
      //lista: "",
      //receituario: "",
      //tipoControle: "",
      rms: "",
      idUnidadeMedida: 1,
      empresas: [
        {
          desconto: 0,
          descontoMaximo: 0,
          estoqueMinimo: 0,
          idRegraFiscal: 0,
          margem: 0,
          idEmpresa: 0,
          precoAberto: false,
          precoCompra: 0,
          valor: 0,
        },
      ],
    },
  });

  const mutation = useMutation<any, any, CriarProdutoDtoType>({
    mutationFn: (p: CriarProdutoDtoType) => {
      return axios.post("/api/produto", p);
    },
    onError: (error) => {
      const transformErrors = (apiErrors: Record<string, string[]>) => {
        const errors = {} as any;

        for (const [key, messages] of Object.entries(apiErrors)) {
          const formattedKey = key
            .replace(/\[\[\d+\]\]/g, (match) => `[${match.slice(2, -2)}]`) // Altera [[n]] para [n]
            .replace(/^\w/, (c) => c.toLowerCase()) // Torna a primeira letra minúscula
            .replace(/(\.\w)/g, (match) => match.toLowerCase()); // Torna minúsculas as letras após um ponto

          errors[formattedKey] = {
            type: "manual",
            message: messages.join(" "),
          };
        }

        return errors;
      };

      const formattedErrors = transformErrors(error.response.data.errors);
      for (const [field, error] of Object.entries(formattedErrors)) {
        form.setError(field as any, error as any);
      }
    },
  });

  const onSubmit: SubmitHandler<CriarProdutoDtoType> = (data) => {
    mutation.mutate(data);
  };

  console.log(form.formState.errors);

  return (
    <div>
      <FormProvider {...form}>
        <PageBreadcrumb items={produtoBreadcrumbItems()} />
        <Form
          noValidate
          className="mb-9"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
            <div>
              <h2 className="mb-2">Cadastrar um produto</h2>
              <h5 className="text-body-tertiary fw-semibold">
                Preencha as informações do produto
              </h5>
            </div>
            <div className="d-flex flex-wrap gap-2">
              <Button variant="phoenix-secondary" type="button">
                Descartar
              </Button>
              <Button
                variant="primary"
                type="submit"
                loading={mutation.isPending}
                disabled={mutation.isPending}
                loadingPosition="start"
              >
                Salvar
              </Button>
            </div>
          </div>
          <Row className="g-5">
            <Col xs={12} xl={8}>
              <h4 className="mb-3">Identificação do produto</h4>
              <Row className="g-2">
                <Form.Group
                  as={Col}
                  xs={12}
                  xl={12}
                  className="mb-5"
                  controlId="descricao"
                >
                  <Form.Control
                    placeholder="Descrição do produto"
                    {...form.register("descricao")}
                    required
                    isInvalid={!!form.formState.errors.descricao}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.descricao?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <div className="mb-5">
                <h4 className="mb-3">Produto por empresa</h4>
                <ProdutoEmpresaPrecosTable />
              </div>

              <div>
                <h4 className="mb-3">Inventário</h4>
                <InventoryTab />
              </div>

              <div className="mt-5">
                <h4 className="mb-3">Regra fiscal por empresa</h4>
                <RegraFiscalEmpresaTable />
              </div>
            </Col>
            <Col xs={12} xl={4}>
              <Row className="g-2">
                <Col xs={12} xl={12}>
                  <OrganizeFormCard className="mb-3" />
                </Col>
                <Col xs={12} xl={12}>
                  <VariantFormCard />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </div>
  );
};

export default NovoProduto;
