import App from "App";
import MainLayout from "layouts/MainLayout";
import Error403 from "pages/error/Error403";
import Error404 from "pages/error/Error404";
import Error500 from "pages/error/Error500";
import Logs from "pages/overwatch/hub";
import Implantacao from "pages/overwatch/implantacao";
import Licenca from "pages/overwatch/licenca";
import Servidores from "pages/overwatch/servidores";
import ForgotPassword from "pages/pages/authentication/simple/ForgotPassword";
import ForgotPasswordConfirm from "pages/pages/authentication/simple/ForgotPasswordConfirm";
import ResetPassword from "pages/pages/authentication/simple/ResetPassword";
import SimpleSignIn from "pages/pages/authentication/simple/SignIn";
import TwoFA from "pages/pages/authentication/simple/TwoFA";
import EditarProduto from "pages/retaguarda/editarProduto";
import NovoProduto from "pages/retaguarda/novoProduto";
import Produto from "pages/retaguarda/novoProduto";

import Produtos from "pages/retaguarda/produtos";
import { Assinatura } from "pages/revenda/assinatura";
import { Cliente } from "pages/revenda/cliente";
import Clientes from "pages/revenda/clientes";
import ProtectedRoute from "ProtectedRoute";
import AuthProvider from "providers/AuthProvider";
import MainLayoutProvider from "providers/MainLayoutProvider";
import { RouteObject, createBrowserRouter } from "react-router-dom";

const routes: RouteObject[] = [
  {
    element: (
      <AuthProvider>
        <App />
      </AuthProvider>
    ),
    errorElement: <Error500 />,
    children: [
      {
        path: "/",
        element: (
          <MainLayoutProvider>
            <ProtectedRoute element={<MainLayout />}></ProtectedRoute>
          </MainLayoutProvider>
        ),
        children: [
          {
            path: "/logs",
            element: <Logs />,
          },
          {
            index: true,
            element: <Clientes />,
          },
          {
            path: "/cliente/:id",
            element: <Cliente />,
          },
          {
            path: "/cliente",
            element: <Cliente />,
          },
          {
            path: "/cliente/:id/assinatura",
            element: <Assinatura />,
          },
          {
            path: "/licenca",
            element: <Licenca />,
          },
          {
            path: "/i/:connId",
            element: <Implantacao />,
          },
          {
            path: "/servidores",
            element: <Servidores />,
          },
          {
            path: "/retaguarda",
            children: [
              {
                path: "cadastros",
                children: [
                  {
                    path: "produtos",
                    children: [
                      {
                        index: true,
                        element: <Produtos />,
                      },
                      {
                        path: ":id",
                        element: <EditarProduto />,
                      },
                      {
                        path: "novo",
                        element: <NovoProduto />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/pages/errors/",
        children: [
          {
            path: "404",
            element: <Error404 />,
          },
          {
            path: "403",
            element: <Error403 />,
          },
          {
            path: "500",
            element: <Error500 />,
          },
        ],
      },
      {
        path: "/pages/authentication/simple/",
        children: [
          {
            path: "sign-in",
            element: <SimpleSignIn />,
          },
          {
            path: "reset-password",
            element: <ResetPassword />,
          },
          {
            path: "2FA",
            element: <TwoFA />,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "forgot-confirm",
            element: <ForgotPasswordConfirm />,
          },
        ],
      },
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);

export default routes;
