import { Dropdown } from "react-bootstrap";

const ActionDropdownItems = () => {
  return (
    <>
      <Dropdown.Item eventKey="1">Visualizar</Dropdown.Item>
      <Dropdown.Item eventKey="2">Históricos</Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item eventKey="4" className="text-danger">
        Inativar
      </Dropdown.Item>
    </>
  );
};

export default ActionDropdownItems;
