import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/base/Button";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const SignInForm = ({
  layout,
  loading,
}: {
  layout: "simple" | "card" | "split";
  loading: boolean;
}) => {
  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Login</h3>
        <p className="text-body-tertiary">Continue com sua conta</p>
      </div>
      {/* <AuthSocialButtons title="Sign in" />
      <div className="position-relative">
        <hr className="bg-body-secondary mt-5 mb-4" />
        <div className="divider-content-center">or use email</div>
      </div> */}
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="email">Email</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="email"
            type="email"
            className="form-icon-input"
            placeholder="nome@exemplo.com"
            required
          />
          <FontAwesomeIcon icon={faUser} className="text-body fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">Senha</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="password"
            type="password"
            className="form-icon-input"
            placeholder="Senha"
            required
          />
          <FontAwesomeIcon icon={faKey} className="text-body fs-9 form-icon" />
        </div>
      </Form.Group>
      <Row className="flex-between-center mb-7">
        <Col xs="auto">
          {/* <Form.Check type="checkbox" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember-me"
              id="remember-me"
              defaultChecked
            />
            <Form.Check.Label htmlFor="remember-me" className="mb-0">
              Lembrar
            </Form.Check.Label>
          </Form.Check> */}
        </Col>
        <Col xs="auto">
          <Link
            to={`/pages/authentication/${layout}/forgot-password`}
            className="fs-9 fw-semibold"
          >
            Esqueceu a senha?
          </Link>
        </Col>
      </Row>
      <Button
        variant="primary"
        className="w-100 mb-3"
        type="submit"
        loading={loading}
        loadingPosition="end"
      >
        Logar
      </Button>
      {/* <div className="text-center">
        <Link
          to={`/pages/authentication/${layout}/sign-up`}
          className="fs-9 fw-bold"
        >
          Criar uma conta
        </Link>
      </div> */}
    </>
  );
};

export default SignInForm;
