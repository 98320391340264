import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Scrollbar from "components/base/Scrollbar";
import { CartItemType } from "data/assinatura";
import {
  CriarProdutoDtoType,
  EmpresaType,
  ProdutoEmpresaType,
} from "data/produto";
import { currencyFormat } from "helpers/utils";
import { ChangeEvent, useEffect } from "react";
import { Form, Table } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";

interface ProdutoEmpresaPrecosTableProps {}

const fetchEmpresas = async () => {
  const { data } = await axios.get("/api/empresa");
  return data;
};

const ProdutoEmpresaPrecosTable = ({}: ProdutoEmpresaPrecosTableProps) => {
  const empresasQuery = useQuery<EmpresaType[]>({
    queryKey: ["empresas"],
    queryFn: fetchEmpresas,
  });

  const { control, setValue } = useFormContext<CriarProdutoDtoType>();

  const { fields } = useFieldArray({
    control,
    name: "empresas",
    rules: { required: true },
  });

  useEffect(() => {
    if (empresasQuery.isSuccess) {
      setValue(
        "empresas",
        empresasQuery.data.map((e) => ({
          idEmpresa: e.id,
          desconto: 0,
          descontoMaximo: 0,
          estoqueMinimo: 0,
          idRegraFiscal: 0,
          margem: 0,
          precoAberto: false,
          precoCompra: 0,
          valor: 0,
        }))
      );
    }
  }, [empresasQuery.isSuccess]);

  return (
    <Scrollbar autoHeight autoHeightMax="100%" className="table-scrollbar">
      <Table className="phoenix-table fs-9 mb-0 border-top border-translucent">
        <thead>
          <tr>
            <th scope="col" style={{ width: 100 }}>
              Empresa
            </th>
            <th className="ps-5 text-center" scope="col">
              Custo
            </th>
            <th className="ps-5 text-center" scope="col">
              Margem
            </th>
            <th className="ps-5 text-center" scope="col">
              Venda
            </th>
            <th className="ps-5 text-center" scope="col">
              Desc Auto
            </th>
            <th className="ps-5 text-center" scope="col">
              Preço c/ Desc
            </th>
            <th className="ps-5 text-center" scope="col">
              Estoque Mínimo
            </th>
            <th className="ps-5 text-center" scope="col">
              Preço Aberto
            </th>
          </tr>
        </thead>
        {empresasQuery.isSuccess && empresasQuery.data.length > 0 && (
          <tbody className="list" id="cart-table-body">
            {fields.map((p, i) => (
              <ProdutoEmpresaPrecosRow
                key={p.id}
                index={i}
                empresa={empresasQuery?.data?.[i]}
              />
            ))}
          </tbody>
        )}
      </Table>
    </Scrollbar>
  );
};

const ProdutoEmpresaPrecosRow = ({
  empresa: { nomeFantasia },
  index,
}: {
  empresa: EmpresaType;
  index: number;
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CriarProdutoDtoType>();

  return (
    <tr className="cart-table-row">
      <td>
        <Link className="fw-semibold line-clamp-2" to="#!">
          {nomeFantasia}
        </Link>
      </td>
      <Form.Group
        as="td"
        className="fs-8 ps-5 text-center"
        controlId={`empresas.${index}.precoCompra`}
      >
        <Form.Control
          type="text"
          {...register(`empresas.${index}.precoCompra`, {
            required: "Campo obrigatório",
          })}
          isInvalid={!!errors?.empresas?.[index]?.precoCompra}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.precoCompra?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group
        as="td"
        className="fs-8 ps-5 text-center"
        controlId={`empresas.${index}.margem`}
      >
        <Form.Control
          type="text"
          {...register(`empresas.${index}.margem`, {
            required: "Campo obrigatório",
          })}
          isInvalid={!!errors?.empresas?.[index]?.margem}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.margem?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group
        as="td"
        className="fs-8 ps-5 text-center"
        controlId={`empresas.${index}.valor`}
      >
        <Form.Control
          type="text"
          {...register(`empresas.${index}.valor`, {
            required: "Campo obrigatório",
          })}
          isInvalid={!!errors?.empresas?.[index]?.valor}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.valor?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group
        as="td"
        className="fs-8 ps-5 text-center"
        controlId={`empresas.${index}.desconto`}
      >
        <Form.Control
          type="text"
          {...register(`empresas.${index}.desconto`, {
            required: "Campo obrigatório",
          })}
          isInvalid={!!errors?.empresas?.[index]?.desconto}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.desconto?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group
        as="td"
        className="fs-8 ps-5 text-center"
        controlId={`empresas.${index}.descontoMaximo`}
      >
        <Form.Control
          type="text"
          {...register(`empresas.${index}.descontoMaximo`, {
            required: "Campo obrigatório",
          })}
          isInvalid={!!errors?.empresas?.[index]?.descontoMaximo}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.descontoMaximo?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group
        as="td"
        className="fs-8 ps-5 text-center"
        controlId={`empresas.${index}.estoqueMinimo`}
      >
        <Form.Control
          type="text"
          {...register(`empresas.${index}.estoqueMinimo`, {
            required: "Campo obrigatório",
          })}
          isInvalid={!!errors?.empresas?.[index]?.estoqueMinimo}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.estoqueMinimo?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group
        as="td"
        className="fs-8 ps-5 text-center"
        controlId={`empresas.${index}.precoAberto`}
      >
        <Form.Check
          type="switch"
          {...register(`empresas.${index}.precoAberto`)}
          isInvalid={!!errors?.empresas?.[index]?.precoAberto}
          defaultChecked={false}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.precoAberto?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </tr>
  );
};

export default ProdutoEmpresaPrecosTable;
