import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Button from "components/base/Button";
import { Form } from "react-bootstrap";

const ForgotPasswordForm = ({
  layout,
  onSubmit,
  loading,
}: {
  layout?: "simple" | "card" | "split";
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  loading?: boolean;
}) => {
  return (
    <div className={classNames({ "px-xxl-5": !(layout === "split") })}>
      <div
        className={classNames("text-center", { "mb-6": !(layout === "split") })}
      >
        <h4 className="text-body-highlight">Esqueceu a senha?</h4>
        <p className="text-body-tertiary mb-5">
          Digite seu email abaixo e nós enviaremos <br className="d-sm-none" />
          um código para redefinir sua senha.
        </p>
        <Form className="d-flex align-items-center mb-5" onSubmit={onSubmit}>
          <Form.Control
            type="email"
            id="email"
            className="flex-1"
            placeholder="Email"
            required
          />
          <Button
            variant="primary"
            className="ms-2"
            type="submit"
            loading={loading}
            loadingPosition="start"
            endIcon={<FontAwesomeIcon icon={faChevronRight} className="ms-2" />}
          >
            Enviar
          </Button>
        </Form>
        {/* <Link to="#!" className="fs-9 fw-bold">
          Still having problems?
        </Link> */}
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
