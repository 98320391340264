import Scrollbar from "components/base/Scrollbar";
import { CartItemType } from "data/assinatura";
import { ChangeEvent } from "react";
import { Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

interface AssinaturaTableProps {
  products: CartItemType[];
  addProduct: (product: CartItemType) => void;
  removeProduct: (product: CartItemType) => void;
}

const AssinaturaTable = ({
  products,
  addProduct,
  removeProduct,
}: AssinaturaTableProps) => {
  return (
    <Scrollbar autoHeight autoHeightMax="100%" className="table-scrollbar">
      <Table className="phoenix-table fs-9 mb-0 border-top border-translucent">
        <thead>
          <tr>
            <th scope="col" style={{ width: 330 }}>
              Produto
            </th>
            <th className="ps-5 text-center" scope="col" style={{ width: 30 }}>
              Habilitado
            </th>
          </tr>
        </thead>
        <tbody className="list" id="cart-table-body">
          {products.map((product) => (
            <AssinaturaTableRow
              product={product}
              key={product.id}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                e.target.checked ? addProduct(product) : removeProduct(product)
              }
              isEnabled={product.isEnabled}
            />
          ))}
        </tbody>
      </Table>
    </Scrollbar>
  );
};

const AssinaturaTableRow = ({
  product,
  onChange,
  isEnabled,
}: {
  product: CartItemType;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  isEnabled: boolean;
}) => {
  return (
    <tr className="cart-table-row" key={product.id}>
      <td>
        <Link className="fw-semibold line-clamp-2" to="#!">
          {product.name}
        </Link>
      </td>
      <td className="fs-8 ps-5 text-center">
        <Form.Check type="switch" checked={isEnabled} onChange={onChange} />
      </td>
    </tr>
  );
};

export default AssinaturaTable;
