import axios from "axios";
import Button from "components/base/Button";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { clienteBreadcrumbItems } from "data/cliente";
import { useEffect, useState } from "react";
import { Alert, Col, Form, Row, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";

const fetchUser = async ({ queryKey }: any) => {
  const [, id] = queryKey;
  const { data } = await axios.get(
    process.env.REACT_APP_API_CUSTOMER_URL + "/customer/" + id
  );
  return data;
};

const fetchProducts = async () => {
  const { data } = await axios.get(
    process.env.REACT_APP_API_CUSTOMER_URL + "/product"
  );
  return data;
};

const fetchEndereco = async ({ queryKey }: any) => {
  const [, cep] = queryKey;
  const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`, {
    withCredentials: false,
  });
  return data;
};

const fetchEmpresa = async ({ queryKey }: any) => {
  const [, cnpj] = queryKey;
  const cnpjLimpo = cnpj.replace(/\D/g, "");
  const { data } = await axios.get(
    `https://brasilapi.com.br/api/cnpj/v1/${cnpjLimpo}`,
    {
      withCredentials: false,
    }
  );
  return data;
};

const novoCliente = async (data: any) => {
  return axios.post(`${process.env.REACT_APP_API_CUSTOMER_URL}/customer`, data);
};

const editarCliente = (id: string) => {
  return async (data: any) => {
    return axios.put(
      `${process.env.REACT_APP_API_CUSTOMER_URL}/customer/${id}`,
      data
    );
  };
};

interface FormData {
  razaoSocial: string;
  nomeFantasia: string;
  cnpj: string;
  responsavel: string;
  renewInterval: string;
  active: boolean;
  email: string;
  phone: string;
}

interface EnderecoViaCep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface Qsa {
  nome_socio: string;
}
interface EmpresaBrasilApi {
  cnpj: string;
  qsa: Array<Qsa>;
  email: string;
  bairro: string;
  numero: string;
  cep: string;
  municipio: string;
  logradouro: string;
  uf: string;
  complemento: string;
  razao_social: string;
  nome_fantasia: string;
  ddd_telefone_1: string;
}

export function Cliente() {
  const { id } = useParams();
  const { data, error, isLoading } = useQuery({
    queryFn: fetchUser,
    queryKey: ["customer", id],
    enabled: !!id,
    //disable caching
    cacheTime: 0,
  });
  const { data: products, isLoading: loadingProducts } = useQuery({
    queryFn: fetchProducts,
    cacheTime: 0,
  });

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    razaoSocial: "",
    nomeFantasia: "",
    cnpj: "",
    responsavel: "",
    active: false,
    email: "",
    phone: "",
    avantParceiroId: "",
    avantToken: "",
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
  });

  const { refetch: refetchEndereco } = useQuery<EnderecoViaCep>({
    queryFn: fetchEndereco,
    queryKey: ["endereco", formData.cep],
    enabled: false,
  });

  const { refetch: refetchEmpresa } = useQuery<EmpresaBrasilApi>({
    queryFn: fetchEmpresa,
    queryKey: ["empresa", formData.cnpj],
    enabled: false,
  });

  const [errors, setErrors] = useState<any>({});

  const mutation = useMutation({
    mutationFn: id ? editarCliente(id) : novoCliente,
    onError: (error: any) => {
      const toLowerCaseKeys = (obj: any) => {
        return Object.keys(obj).reduce((acc: any, key) => {
          const lowerCaseKey = key[0].toLowerCase();
          const restOfKey = key.slice(1);
          const newKey = lowerCaseKey + restOfKey;
          acc[newKey] = obj[key];
          return acc;
        }, {});
      };

      const transformedObject = toLowerCaseKeys(error.response.data.errors);
      console.log(transformedObject);
      setErrors(transformedObject);
    },
    onSuccess: () => {
      navigate("/");
    },
  });

  useEffect(() => {
    if (data) {
      setFormData({
        razaoSocial: data.razaoSocial,
        nomeFantasia: data.nomeFantasia,
        cnpj: data.cnpj,
        responsavel: data.responsavel,
        active: data.active,
        email: data.email,
        phone: data.phone,
        avantParceiroId: data.avantParceiroId,
        avantToken: data.avantToken,
        cep: data.cep,
        logradouro: data.logradouro,
        numero: data.numero,
        complemento: data.complemento,
        bairro: data.bairro,
        cidade: data.cidade,
        estado: data.estado,
      });
    }
  }, [data]);

  if (isLoading || loadingProducts)
    return (
      <Spinner animation="border" role="status" className="d-block mx-auto">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  if (error) return <span>Error loading data</span>;

  const handleSalvar = async (e: any) => {
    e.preventDefault();
    setErrors({} as FormData);
    await mutation.mutate(formData as any);
  };

  const handleChange = (e: any) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const handleBuscaCep = async () => {
    if (!formData.cep || id) return;
    const r = await refetchEndereco({
      queryKey: ["endereco", formData.cep],
    });

    if (!r.data) return;

    setFormData((prevFormData) => ({
      ...prevFormData,
      logradouro: r.data.logradouro,
      bairro: r.data.bairro,
      cidade: r.data.localidade,
      estado: r.data.uf,
    }));
  };

  const handleBuscaEmpresa = async () => {
    if (!formData.cnpj || id) return;
    const r = await refetchEmpresa({
      queryKey: ["empresa", formData.cnpj],
    });

    if (!r.data) return;

    setFormData((prevFormData) => ({
      ...prevFormData,
      razaoSocial: r.data.razao_social,
      nomeFantasia: r.data.nome_fantasia,
      email: r.data.email,
      bairro: r.data.bairro,
      numero: r.data.numero,
      logradouro: r.data.logradouro,
      complemento: r.data.complemento,
      cidade: r.data.municipio,
      estado: r.data.uf,
      phone: r.data.ddd_telefone_1,
      responsavel: r.data.qsa[0]?.nome_socio,
      cep: r.data.cep,
    }));
  };

  return (
    <div>
      <PageBreadcrumb items={clienteBreadcrumbItems(id)} />
      <div className="mb-9">
        <h1 className="mb-5">{id ? "Editar" : "Novo"} Cliente</h1>
        <form className="row g-3" onSubmit={handleSalvar}>
          {mutation.isError && (
            <Alert variant={"phoenix-danger"}>
              Ocorreu um erro ao salvar os dados.
            </Alert>
          )}

          <h3>Informações Básicas</h3>
          <Row className="g-2">
            <Col md={2}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="cnpj">
                  CNPJ
                </label>
                <Form.Control
                  id="cnpj"
                  type="text"
                  placeholder="CNPJ"
                  value={formData.cnpj}
                  onChange={handleChange}
                  isInvalid={!!errors.cnpj}
                  onBlur={handleBuscaEmpresa}
                />
                {errors.cnpj && (
                  <Form.Control.Feedback type="invalid">
                    {errors.cnpj[0]}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={5}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="razaoSocial">
                  Razão Social
                </label>
                <Form.Control
                  id="razaoSocial"
                  type="text"
                  placeholder="Razão Social"
                  value={formData.razaoSocial}
                  onChange={handleChange}
                  isInvalid={!!errors.razaoSocial}
                />
                {errors.razaoSocial && (
                  <Form.Control.Feedback type="invalid">
                    {errors.razaoSocial[0]}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={5}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="nomeFantasia">
                  Nome Fantasia
                </label>
                <Form.Control
                  id="nomeFantasia"
                  type="text"
                  placeholder="Nome Fantasia"
                  value={formData.nomeFantasia}
                  onChange={handleChange}
                  isInvalid={!!errors.nomeFantasia}
                />
                {errors.nomeFantasia && (
                  <Form.Control.Feedback type="invalid">
                    {errors.nomeFantasia[0]}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="responsavel">
                  Responsável
                </label>
                <Form.Control
                  id="responsavel"
                  type="text"
                  placeholder="Responsável"
                  value={formData.responsavel}
                  onChange={handleChange}
                  isInvalid={!!errors.responsavel}
                />
                {errors.responsavel && (
                  <Form.Control.Feedback type="invalid">
                    {errors.responsavel[0]}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="email">
                  Email
                </label>
                <Form.Control
                  id="email"
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                {errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email[0]}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="phone">
                  Telefone
                </label>
                <Form.Control
                  id="phone"
                  type="text"
                  placeholder="Telefone"
                  value={formData.phone}
                  onChange={handleChange}
                  isInvalid={!!errors.phone}
                />
                {errors.phone && (
                  <Form.Control.Feedback type="invalid">
                    {errors.phone[0]}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <h3 className="mt-5">Endereço</h3>
          <Row className="g-2">
            <Col md={4}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="cep">
                  CEP
                </label>
                <Form.Control
                  id="cep"
                  type="text"
                  placeholder="CEP"
                  value={formData.cep}
                  onChange={handleChange}
                  isInvalid={!!errors.cep}
                  onBlur={handleBuscaCep}
                />
                {errors.cep && (
                  <Form.Control.Feedback type="invalid">
                    {errors.cep[0]}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="logradouro">
                  Logradouro
                </label>
                <Form.Control
                  id="logradouro"
                  type="text"
                  placeholder="Logradouro"
                  value={formData.logradouro}
                  onChange={handleChange}
                  isInvalid={!!errors.logradouro}
                />
                {errors.logradouro && (
                  <Form.Control.Feedback type="invalid">
                    {errors.logradouro[0]}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="numero">
                  Número
                </label>
                <Form.Control
                  id="numero"
                  type="text"
                  placeholder="Número"
                  value={formData.numero}
                  onChange={handleChange}
                  isInvalid={!!errors.numero}
                />
                {errors.numero && (
                  <Form.Control.Feedback type="invalid">
                    {errors.numero[0]}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="complemento">
                  Complemento
                </label>
                <Form.Control
                  id="complemento"
                  type="text"
                  placeholder="Complemento"
                  value={formData.complemento}
                  onChange={handleChange}
                  isInvalid={!!errors.complemento}
                />
                {errors.complemento && (
                  <Form.Control.Feedback type="invalid">
                    {errors.complemento[0]}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="bairro">
                  Bairro
                </label>
                <Form.Control
                  id="bairro"
                  type="text"
                  placeholder="Bairro"
                  value={formData.bairro}
                  onChange={handleChange}
                  isInvalid={!!errors.bairro}
                />
                {errors.bairro && (
                  <Form.Control.Feedback type="invalid">
                    {errors.bairro[0]}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="cidade">
                  Cidade
                </label>
                <Form.Control
                  id="cidade"
                  type="text"
                  placeholder="Cidade"
                  value={formData.cidade}
                  onChange={handleChange}
                  isInvalid={!!errors.cidade}
                />
                {errors.cidade && (
                  <Form.Control.Feedback type="invalid">
                    {errors.cidade[0]}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="estado">
                  Estado
                </label>
                <Form.Control
                  id="estado"
                  type="text"
                  placeholder="Estado"
                  value={formData.estado}
                  onChange={handleChange}
                  isInvalid={!!errors.estado}
                />
                {errors.estado && (
                  <Form.Control.Feedback type="invalid">
                    {errors.estado[0]}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>

          <h3 className="mt-5">Integrações</h3>
          <Row className="g-2">
            <Col md={4}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="avantParceiroId">
                  Avant Parceiro Id
                </label>
                <Form.Control
                  type="text"
                  id="avantParceiroId"
                  onChange={handleChange}
                  value={formData.avantParceiroId}
                />
              </Form.Group>
            </Col>
            <Col md={8}>
              <Form.Group>
                <label className="form-label ps-0" htmlFor="avantToken">
                  Token
                </label>
                <Form.Control
                  type="text"
                  id="avantToken"
                  onChange={handleChange}
                  value={formData.avantToken}
                />
              </Form.Group>
            </Col>
          </Row>

          <Col xs={12} className="mt-5 d-flex flex-row gap-2 flex-wrap">
            <Form.Check
              id="active"
              type="checkbox"
              label="Ativo"
              checked={formData.active}
              onChange={handleChange}
            />
          </Col>

          <Col xs={12} className="mt-5 d-flex justify-content-end gap-3">
            <Button
              variant="phoenix-secondary"
              className="text-nowrap"
              type="button"
              as={Link}
              to="/"
            >
              Sair sem salvar
            </Button>
            <Button
              variant="outline-info"
              className="text-nowrap"
              type="button"
              as={Link}
              to="assinatura"
            >
              Assinatura
            </Button>
            <Button
              variant="primary"
              className="px-8 px-sm-11 me-2"
              type="submit"
              loading={mutation.isLoading}
              loadingPosition="end"
            >
              Salvar
            </Button>
          </Col>
        </form>
      </div>
    </div>
  );
}
