import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export const implantacaoBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: "Revenda",
    url: "#!",
  },
  {
    label: "Implantação",
    active: true,
  },
];
